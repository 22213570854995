<template>
    <div class="app-container">
        <el-input
            v-model="queryInfo.query"
            clearable
            size="small"
            placeholder="输入名称搜索"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="crud.toQuery"
        />
        <span>
            <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="getTableData()">搜索</el-button>
        </span>
        <div  class="crdc-opts">
          <span>
            <slot name="left" />
            <el-button
              class="filter-item"
              size="mini"
              type="primary"
              icon="el-icon-plus"
              @click="add()"
            >
              新增
            </el-button>
            <!-- <el-button
              class="filter-item"
              size="mini"
              type="success"
              icon="el-icon-edit"
            >
              修改
            </el-button>
            <el-button
              slot="reference"
              class="filter-item"
              type="danger"
              icon="el-icon-delete"
              size="mini"
            >
              删除
            </el-button> -->
          </span>
          <!--右侧-->
            <slot name="right" />
          <el-button-group class="crud-opts-right">
              <el-button
                size="mini"
                icon="el-icon-refresh"
                @click="getTableData()"
              />
            </el-button-group>
        </div>
        <el-table
        :data="tableData"  highlight-current-row
        @current-change="companyCurrentChange"
        border stripe
        style="width: 100%">
        <el-table-column
          fixed
          prop="company_ID"
          label="关联公司"
          min-width="80">
        </el-table-column>
        <el-table-column
          fixed
          prop="company_NAME"
          label="公司名称"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="news_LEVEL_NAME"
          label="舆情级别"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="news_CONTENT"
          label="舆情内容"
          min-width="400">
        </el-table-column>
        <el-table-column
          prop="task_STATUS_NAME"
          label="任务状态"
          min-width="80">
        </el-table-column>
        <el-table-column
          prop="sponse_NAME"
          label="任务发起人"
          min-width="100">
        </el-table-column>
        <el-table-column
          prop="process_NAME"
          label="最后任务处理人"
          min-width="110">
        </el-table-column>
        <el-table-column
          prop="process_OPINION"
          label="意见"
          min-width="150">
        </el-table-column>
        <el-table-column
          prop="create_DATETIME"
          label="创建时间"
          min-width="150" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          label="操作" align="center"
          min-width="180">
          <template slot-scope="scope">
            <el-button type="primary" :disabled="scope.row.task_STATUS != 0" icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" ></el-button>
            <el-button type="danger" :disabled="scope.row.task_STATUS != 0" icon="el-icon-delete" size="mini" @click="deleteFrom(scope.$index, scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-card>
          <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pagenum"
        :page-sizes="[2, 5, 10, 15]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totle"
      ></el-pagination>
      <el-dialog append-to-body  title="主体信息修改" :visible.sync="addDialogVisible" width="25%" @close='resetForm'>
          <el-form ref="form"  :model="form" :rules="rules"  label-width="80px" class="demo-ruleForm">
            <div>
            <el-form-item label="关联公司" prop="company_ID" >
                <el-select clearable style="width:280px"  v-model="form.company_ID" :filter-method="userFilter" filterable placeholder="请选择">
                <el-option
                    v-for="item in company_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="舆情级别" prop="news_LEVEL">
              <el-select clearable style="width:280px"  v-model="form.news_LEVEL"  placeholder="请选择">
                <el-option
                    v-for="item in news_level_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            </div>
            <el-form-item label="舆情内容" prop="news_CONTENT">
              <el-input  :autosize="{ minRows: 4}" type="textarea" v-model="form.news_CONTENT"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button type="text" @click="resetForm()" >取消</el-button>
            <el-button v-if="button_value == 1" type="primary" @click="submitFrom('form')">新增</el-button>
            <el-button v-if="button_value == 2" type="primary" @click="updateFrom('form')">提交</el-button>
          </div>
      </el-dialog>
      </el-card>
    </div>
</template>
<script>
export default {
  name: 'News',
  created () { //  初始化
    this.getTableData()
  },
  methods: {
    async getTableData () {
      const { data: res } = await this.$http.get('query/news/levels', {
        params: this.queryInfo
      })
      this.tableData = res.list
      this.totle = res.total
    },
    async queryCompanyNode () {
      const { data: res } = await this.$http.get('query/company/node')
      this.all_company_options = res
      this.userFilter()
    },
    async queryNewsLevelNode () {
      const { data: res } = await this.$http.get('query/news/level/node')
      this.news_level_options = res
    },
    companyCurrentChange (val) {
    },
    userFilter (query = '') {
      const arr = this.all_company_options.filter((item) => {
        return item.label.includes(query) || item.value.includes(query)
      })
      if (arr.length > 50) {
        this.company_options = arr.slice(0, 50)
      } else {
        this.company_options = arr
      }
    },
    add () {
      this.queryCompanyNode()
      this.queryNewsLevelNode()
      this.addDialogVisible = true
      this.button_value = 1
    },
    handleEdit (index, row) {
      this.queryCompanyNode()
      this.queryNewsLevelNode()
      this.addDialogVisible = true
      this.button_value = 2
      //  this.form = row
      this.form.task_ID = row.task_ID
      this.form.news_CONTENT = row.news_CONTENT + ''
      this.form.company_ID = row.company_ID + ''
      this.form.news_LEVEL = row.news_LEVEL + ''
    },
    resetForm (formName) {
      this.addDialogVisible = false
      this.$refs.form.resetFields()
    },
    async submitFrom (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post('insert/news/level', this.form).then(result => {
            this.resetForm()
            this.getTableData()
          })
        }
      })
    },
    async updateFrom (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateLevel()
        }
      })
    },
    async updateLevel () {
      await this.$http.post('update/news/level', this.form)
      this.resetForm()
      this.getTableData()
    },
    async deleteFrom (index, row) {
      await this.$http.post('delete/news/level', row)
      this.getTableData()
    },
    // 监听 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.pagesize = newSize
      this.getTableData()
    },
    // 监听 页码值 改变事件
    handleCurrentChange (newSize) {
      this.queryInfo.pagenum = newSize
      this.getTableData()
    }
  },
  data () {
    return {
      queryInfo: {
        query: '',
        pagenum: 1,
        // 每页显示多少数据
        pagesize: 10
      },
      totle: 0,
      form: {
        task_ID: '',
        company_ID: '',
        news_LEVEL: '',
        news_CONTENT: ''
      },
      button_value: '1',
      tableData: [],
      addDialogVisible: false,
      all_company_options: [],
      company_options: [],
      news_level_options: [],
      rules: {
        company_ID: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        news_LEVEL: [
          { required: true, message: ' ', trigger: 'change' }
        ],
        news_CONTENT: [
          { required: true, message: ' ', trigger: 'change' }
        ]
      }
    }
  }
}
</script>
<style>
.crdc-opts {
  padding: 6px 0;
  display: -webkit-flex;
  display: flex;
  align-items: center;
}
.crdc-opts .crud-opts-right {
  margin-left: auto;
}
</style>
